import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import Firebase, { FirebaseContext } from './components/firebase';

import * as serviceWorker from './serviceWorker';

import './custom.scss';

import 'bootstrap/dist/js/bootstrap.bundle.min';

ReactDOM.render(

<FirebaseContext.Provider value={new Firebase()}>
    <App />
</FirebaseContext.Provider>
, document.getElementById('root'));


serviceWorker.unregister();
