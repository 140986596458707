const objet = [
  {
    level: 0,
    phrase: "Mon pote oblige tout le monde à travailler",
    mot: "b4929585b2bad9701bfafbfc69602739045470335d3f3537529cec7d5e2e5db0",
    valide: false
  },
  {
    level: 0,
    phrase: "Le style offre beaucoup de possibilités",
    mot: "c9c24ee44dc60c14fd63b99075aee3abd4f5f8bff2c3a06ab7efe9d40d02d3f4",
    valide: false
  },
  {
    level: 0,
    phrase: "Il faut tenir sa batte horizontalement",
    mot: "25c9ec1ed3674b8810b459eebe2e4130992373b8db72206e4c5883526004eb6c",
    valide: false
  },
  {
    level: 0,
    phrase: "La lune est tellement belle",
    mot: "4f88f413d457aff84f9a9d2e294c4e8c219ce084c27bb9f9d7d564df8f5e46dd",
    valide: false
  },
  {
    level: 0,
    phrase: "La cave est restée ouverte",
    mot: "5a8a59d98881c1b312a83d7d939796c842d7604982a5cf8d5ddc6d9d6c5d833f",
    valide: false
  },
  {
    level: 0,
    phrase: "Je mets de la crème pour enlever les rides au front",
    mot: "b6584c5bc468aff237f33f6cda02919d4fc3ba83a05e87ce9eef495ee933657c",
    valide: false
  },
  {
    level: 0,
    phrase: "La musique sur le quai se renouvelle",
    mot: "3e305d8bf7203b4c4c1b2b6675f17ae486594739fe26f27c8f19d5b404d391cd",
    valide: false
  },
  {
    level: 0,
    phrase: "Super man est tellement fort",
    mot: "21a2e0b47e0d9fd5734518e586c63bfccb2dc38861277b3cbbd639838d367d3f",
    valide: false
  },
  {
    level: 0,
    phrase: "Ils m’ont traité comme un moins-que-rien",
    mot: "d22d6cfa869c413091e635b8af15f3ae6a93d9c7bdf94acfc385f83825d2b53e",
    valide: false
  },
  {
    level: 0,
    phrase: "Les bateaux se garent dans le port tellement de fois que je ne les compte plus",
    mot: "002c16d9a88473bd07de07d4a27c0388150c5f16d213640678bec5bdd9542480",
    valide: false
  },
  {
    level: 1,
    phrase: "Les gobelins font du MMA, à ce qu'il parait",
    mot: "51bb0f998c8d9ac16f9ea044dba7d402d9b61b373ee69d7ba95b7643f437edf8",
    valide: false
  },
  ]

  export { objet }