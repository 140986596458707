import React from 'react';

import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Home from './screen/Home' ;
import SignUpScreen from './screen/SignUpScreen' ;
import Training from './screen/TrainingScreen' ;
import PlayScreen from './screen/PlayScreen' ;
import UpdateScreen from './screen/UpdateScreen' ;

import ReactNotifications from 'react-notifications-component';

function App() {
  
  return (
    <>
      <ReactNotifications />
      <Router> 

        <Switch>
            <Route path='/' exact component={Home} />
            <Route path='/signUp' exact component={SignUpScreen} />
            <Route path='/training' exact component={Training} />
            <Route path='/playScreen/:theme' exact component={PlayScreen} />
            <Route path='/updateScreen' exact component={UpdateScreen} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
