import React, { useState , useEffect, useContext} from 'react';
import { FirebaseContext } from '../components/firebase';
import { Link} from 'react-router-dom';

import { mapTheme} from '../state/data-play';

import ModalInfo from '../components/ModalInfo';

import ModalWarning from '../components/ModalWarning';

import Menu from '../components/Menu';


import $ from 'jquery';

import { listNameTheme } from '../data/_ListTheme'

function getRandomTheme () {  

  let index = Math.floor((Math.random() * listNameTheme.length) + 0)
  //console.log(index+" result list theme : "+listNameTheme);
  return listNameTheme[index];
}

function Home(props) {
  const firebase = useContext(FirebaseContext);
  //console.log(firebase);
  //const [score , setScore ] = useState(null);
  const randomTheme = getRandomTheme();
  
  const [userSession, setUserSession ] = useState(null);
  const [user , setUser] = useState({});  
  const [ranking , setRanking ] = useState([]);
  const [shwoModalWarning,setShowModalWarning] = useState(false);
  const [showModalTraining,setShowModalTraining] = useState(false),
    [currentTheme,setCurrentTheme] = useState(randomTheme);
  

  const openModalWarning = (bool,theme) => {
    setShowModalWarning(bool);
    setCurrentTheme(theme);
  }

  const scroll = () => {
    const slider = document.querySelector('.items');
    let isDown = false;
    let startX;
    let scrollLeft;

    slider.addEventListener('mousedown', (e) => {
      isDown = true;
      slider.classList.add('active');
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    });
    slider.addEventListener('mouseleave', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mouseup', () => {
      isDown = false;
      slider.classList.remove('active');
    });
    slider.addEventListener('mousemove', (e) => {
      if(!isDown) return;
      e.preventDefault();
      const x = e.pageX - slider.offsetLeft;
      const walk = (x - startX) * 3; //scroll-fast
      slider.scrollLeft = scrollLeft - walk;
     // console.log(walk);
    });
  }
  useEffect (() => {
    // retrieve ranking user
    let listener = firebase.auth.onAuthStateChanged(user => {
      user ? setUserSession(user) : setUserSession(null);
    })
    
    scroll();
    
    if(userSession !== null) {
      firebase.user(userSession.uid)
      .get()
      .then( doc => {
        if (doc && doc.exists){
          const myData = doc.data();
          let score = 0;
          
          // on calcul le score 
          listNameTheme.forEach(function ( th ) {
            
            if (myData[th+""]) {
              
              // prise en compte du level de la phrase
              score += myData[th+""].length ;
              
            }
          });
         // setScore(score);

          setUser({...myData, score });
          //console.log(" data : "+JSON.stringify(myData));
          
        }
      })
      .catch( error => {
        //console.log(" error : "+error.message);
      })
    }
    else
      //(" user session null");

    // retrieve ranking in db
    firebase.rankingGet().get().then((doc) => {
      if (doc.exists) {
          //console.log("Document data:", doc.data().ranking);
          let newRanking = doc.data().ranking;
          let i = 0;
          while ( newRanking[i] && i < 10 ) {
            newRanking[i].pos = i+1;
           // console.log("enter sortir")
            i ++;
          }
          //console.log("taille : " + newRanking.length + " result " + JSON.stringify(newRanking));

          setRanking(newRanking);
      } else {
          //console.log("No such document!");
      }
    }).catch((error) => {
        //console.log("Error getting document:", error);
    });

    return () => {
      listener();
    }
   }, [firebase,userSession]);

  return (
    <div style={{minHeight: "70vh",width:"100ovh"}} className="row">
      <div className="m-auto">
        
      <div className="container center-block text-white">
        <div className="row text-center">
          
        <div className="container-fluid  ">

          <div className="row mt-1">
            <div className="col-4">
            {userSession !== null && <h6 className="text-light mr-2 mt-4 border rounded p-1">
                { "Score : "+user.score }</h6>}
            </div>

            <div className="col-4 text-center ">
              <p className="textSize font-weight-bold ">
                SentenSplit
              </p>
            </div>

              <div className="col-4 text-right ">

                { userSession !== null ? 
                <Menu user={user}/>
                :
                <>
                <Link to='/signUp' className="btn btn-light ">
                  connexion
                </Link>
                </>}
              </div>
              
          </div>
        </div>


          <p className="col-12 display-4 ">
            Bienvenue sur SentenSplit ! 
            </p>
            
            <h5 className="col-12">
            Il s'agira ici de trouver le mot qui se cache dans la phrase. <span className="text-warning">Simple ?</span><br/>
            Pourras-tu trouver le mot caché ?
          </h5>
          
          <div id="flot" className="col-12 p-3 h-100">
            <ModalInfo show={showModalTraining}
            setShowModalTraining={(val) => setShowModalTraining(val)}
            />
          </div>
          
          <div className="col-12">
           <p>Veuillez choisir un thème pour jouer ↓ </p>
          </div> 

          <div className="grid-container shadowStyle2">
              <div className="items">
              <div className="item text-center  border p-3 rounded shadowStyle2" 
                    onClick={() => 
                      userSession !== null ? props.history.push("/playScreen/"+randomTheme) :
                      openModalWarning(true,randomTheme)}
                    >
                        <h1 >Aleatoire</h1>
                        <span role="img" className="display-3" aria-label={"random"}>⁉️</span> 
                    </div>
                {listNameTheme.map(theme =>
                    <div key={theme} className="item text-center  border p-3 rounded shadowStyle2" 
                    onClick={() => 
                      userSession !== null ? props.history.push("/playScreen/"+theme) :
                      openModalWarning(true,theme)
                    }
                    >
                        <h1 >{ theme }</h1>
                        <span role="img" className="display-3" aria-label={theme}>{mapTheme[theme]}</span> 
                    </div>
                    )}
              </div>
              
            
          </div>

          <div id="ranking" className="col-10 mt-4 mr-auto ml-auto text-center" >

            <h2 className="text-warning "> Classement </h2>

            <table className="table shadow">
              <thead className="thead-dark">
                <tr>
                  <th scope="col">Rang</th>
                  <th scope="col">Pseudo</th>
                  <th scope="col">Score</th>
                </tr>
              </thead>
              <tbody className="bg-light">
              {ranking && ranking.map((u,index) => 
                <tr key={u.pseudo+(index+=1)} >
                  <th scope="row">{index}</th>
                  <td>{u.pseudo} 
                  <span role="img" aria-labelledby="star"> {index <= 1 ? "⭐🏆⭐" : index === 2 ?  "🏆" : index === 3 ? "🏅" : ""} </span></td>
                  <td>{u.score} </td> 

                </tr>
              )
            }
              </tbody>
            </table>

            <p>Un nouveau classement quotidient </p>
          </div>

          <ModalWarning 
            show={shwoModalWarning}
            setShowModalWarning={setShowModalWarning}
            openModalTraining={function() {
              setShowModalWarning(false);
              setShowModalTraining(true);
            }}
            theme={currentTheme}
          />


            </div>
        </div>
    </div>

  </div>

  );
}

export default Home;

