

 const mapTheme = {

  manger : "🍖🥡🍊",
 
  animaux : "🐓🐧🐷",
 
  sport : "🏀🏅🏃",
 
  nature : "🌴🌻🥀",
 
  cinema : "🎥🎬🎞️",
 
  objet : "🔧🔌💻",
 
  vetement  : "👞👗👖",
 
  musique  :"🎹🎵🔇",
 
  voyage : "⛱️✈️🚘",
 
  relation  : "💌👨‍👧‍👦😍",

  manga : "📔📖🗾",
 
 }

let data = {

  phrases : []

};

export { data ,mapTheme } 
