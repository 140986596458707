import React, { Component} from 'react';
import { Link } from 'react-router-dom';

import { state } from '../state/data-training';
import { mapTheme } from '../state/data-play';

import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { Modal } from 'react-bootstrap';
import songBonus from './bonus_sound.mp3'

const regex = new RegExp("^[A-z0-9_-]{0,20}$");

class Training extends Component {
  
 constructor(props) {
    super(props);
    
    this.state = {
      showWord : false,
      showModal : false,
    };
    this.nextInputRef = React.createRef();
    this.responseInputRef = React.createRef();
    
    this.handleChange = this.handleChange.bind(this);
  }
  
  handleChange = e => {
     e.preventDefault();
     const { value } = e.target;
     let newState = { ...state };

     if(value.match(regex)){
      if (value.trim().toLowerCase() === newState.phrases[newState.config.id].mot && newState.phrases[newState.config.id].valide === false) {
        this.nextInputRef.current.focus();
        var audio = new Audio(songBonus);
        audio.play();
        store.addNotification({
          title: 'Info',
          message: '+ 1 😉',
          type: 'success',                         // 'default', 'success', 'info', 'warning'
          container: 'top-right',
          insert: "top",                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          
          dismiss: {
            duration: 2000,
            showIcon: true
          }
        })
        
        newState.phrases[newState.config.id].valide = true;
        newState.config.score++;
        newState.config.reponse = "" ;
        
          if(state.config.score >= 10){
            this.handleShowModal(true);
          }
        }
        else{
          newState.config.reponse = value ;
        }
      
      this.setState({ });
    }else{
      store.addNotification({
        title: 'Info',
        message: 'Vous avez saisie une valeur incorrect',
        type: 'danger',                         // 'default', 'success', 'info', 'warning'
        container: 'top-left',
        insert: "top",                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        
        dismiss: {
          duration: 2000,
          showIcon: true,
          pauseOnHover: true
        }
      })
      
    }
   };
  
   
   changePage = e => {
    e.preventDefault();
    const { id } = e.target;
    
    let newState = { ...state };

    newState.config.id = parseInt(id);
    
    this.setState({ showWord : false});
    
    if(!state.phrases[newState.config.id].valide)
      this.responseInputRef.current.focus();
   }

   backPage = () => {
    let newState = { ...state };

    if (newState.config.id > 0 && newState.config.id < 10) {

      newState.config.id--;

      //console.log("newState : " + newState.config.id);
      if(!state.phrases[newState.config.id].valide)
        this.responseInputRef.current.focus();
    }
    
    this.setState({ showWord : false});

   };

   nextPage = () => {
    //console.log(" score : " + state.config.score + " ! ");

    let newState = { ...state };
    
    if (newState.config.id < 9) {

      newState.config.id++;

      //console.log("newState : " + newState.config.id);
      if(!state.phrases[newState.config.id].valide)
        this.responseInputRef.current.focus();
    }
    else{
      //console.log("changement de page impossible : " + newState.config.id);      
    }
    this.setState({showWord : false});

   };

   handleShowModal = (bool) => this.setState({...state, showModal : bool});
  render(){
    const { showModal } = this.state;
    
  return (
    <>
<div className="row pb-2">
  <div className="m-auto">

      <div className="container center-block mt-5 ">
        <div className="row col-md-5 col-12 m-auto justify-content-center text-white">

        <div className="d-flex col-12">
        {state.phrases.map(p => 
         <div key={p.idPhrase} id={p.idPhrase} onClick={this.changePage}
         className={  "shadowStyle p-2 w-25  m-1  btn " + ( (state.config.id === p.idPhrase ? 
          p.valide ? "btn-success border-bottom-2 border-warning" : " btn-warning" 
          :  p.valide ? "btn-success " : "btn-danger" ) )} ></div> 
        )
      }
      </div>
          <div className="col-12 text-center "> 
           <div className="texet-center text-white">{state.config.score}/{state.phrases.length}</div>
          </div>

          {/* ----------- Card du thème -----------*/}
          <div className="col-12 text-center text- border p-3 rounded shadowStyle2" 
          style={
             { backgroundColor : state.phrases[state.config.id].valide ? "green" : "rgb(235, 134, 63)" }
          }>
              <h1 >{ state.phrases[state.config.id].theme.toUpperCase() }</h1>
              <span role="img" className="display-3" aria-label={state.phrases[state.config.id].theme}>{mapTheme[state.phrases[state.config.id].theme]}</span> 
          </div>

          <div className="col-12 text-center mt-4 ">
              <p style={{boxShadow: "inset 0 0 1em rgb(202, 140, 129), 0 0 0.5em rgb(160, 51, 51)"}} className="col-12 ml-auto mr-auto p-3 border  rounded font-weight-bold"> {state.phrases[state.config.id].phrase} </p>
          </div>

          {this.state.showWord &&
            <div className="col-lg-6 col-12 text-center">
              <p style={{boxShadow: "inset 0 0 1em rgb(202, 140, 129), 0 0 0.5em yellow"}} className="col-12 ml-auto mr-auto p-3 border  rounded font-weight-bold">{state.phrases[state.config.id].mot}</p>
            </div>
          }
          
          <div className="col-12 mt-4 mb-4 ">
            <input name="reponse" 
            ref={this.responseInputRef}
              className={"shadowStyle2 form-control " + ( state.phrases[state.config.id].valide ? null : " error" )} 
              placeholder="mot caché..." value={state.config.reponse} onChange={this.handleChange} />
          </div>   

          <div className="col-3 ">
            <button onClick={this.backPage} className="shadowStyle2 btn btn-secondary ">Retour</button>
          </div>
          
          <div  className="col-3 text-center">
            <Link to='/' className=" btn btn-danger">Quitter</Link>
          </div>

          
        <div className="col-3 text-center">
          <button type="button" onClick={() => this.setState({showWord : true})} className={"shadowStyle2 btn btn-warning"}>
            reponse
          </button>
        </div>
        
          <div className="col-3 text-right">
            <button type="button" onClick={this.nextPage }
             ref={this.nextInputRef}
            className={"shadowStyle2 btn " + ( state.phrases[state.config.id].valide ? "btn-success" : "btn-primary " ) }>Suite</button>
          </div>
          
        </div>
      </div> 
      
       <Modal
       show={showModal}
       onHide={() => this.handleShowModal(false)}
       size="lg"
       aria-labelledby="contained-modal-title-vcenter"
       centered
     >
       <Modal.Header closeButton>
         <Modal.Title id="contained-modal-title-vcenter">
         <div className="card-header ">
          <span role="img" aria-label="triangular flag"> 🚩 Fin de partie ! 🚩</span>
          </div>
         </Modal.Title>
       </Modal.Header>
       <Modal.Body className="text-center">
        <div className="card-body">
          <h5 className="card-title"><span role="img" aria-label="fire conffeti">🔥🎊 Félicitation tu es arrivé jusqu'au bout !🎊🔥</span></h5>
          <p className="card-text text-dark" >Si tu es arrivé jusqu'ici tout seul tu es peut-être prêt pour le mode officiel <span role="img" aria-label=" shark">⚔️🦈</span></p>
          <Link to="/" className="btn btn-primary">
            Retour au menu principal
          </Link>
          <button className="btn btn-dark ml-4"
            onClick={() => this.handleShowModal(false)}
          >
            fermer
          </button>
        </div>
         </Modal.Body>
       </Modal>

  </div>
</div>
    </>
  );
  }
}

export default Training;
