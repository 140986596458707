const musique = [
  {
    level: 0,
    phrase: "Il mixe à Genève",
    mot: "b099bed6e7bb52fc356d7008353023b565224c54f0e9d71b4df1b346934a6dcc",
    valide: false
  },
  {
    level: 0,
    phrase: "J'aime bien quand il dit ce que je pense",
    mot: "2af25da4945266b54d2dc4bca0596684edb10163e07e37937ae221476e1db2dc",
    valide: false
  },
  {
    level: 0,
    phrase: "Dans la salle, les chants sont inaudibles",
    mot: "53e797227c4c79b5938dc35b01a7b2402c47f55516fb318e06ed9caac2ea7ff6",
    valide: false
  },
  {
    level: 0,
    phrase: "Ce veau a un grand avenir dans mon assiette",
    mot: "4c552767f07493ced1504e5d891c4bf35a0e232539ceba333e8dd0ed254e45f0",
    valide: false
  },
  {
    level: 0,
    phrase: "Les gens en bas ce rappel de toi",
    mot: "9f18fd2fa0f52c088e3e444af5f07ffca5acf6b8bf0d4e7f7f1187380d075a9c",
    valide: false
  },
  {
    level: 0,
    phrase: "La pie a nommé tous les pigeons \"fainéant professionnel\"",
    mot: "1d78e018e405ef26b7f7581336a2a4c556c3783eaa763978cbd7d2ddd0adb3ad",
    valide: false
  },
  {
    level: 0,
    phrase: "Elle a coordonné toute la troupe de félin",
    mot: "b4ef49fb50c0c07d21028202d3e6cd74ee18ea84dbee07dddd29e8cec99f1d61",
    valide: false
  },
  {
    level: 0,
    phrase: "Je ne jouerai pas cette partie si on ne m'écoute pas",
    mot: "2df962d37ee229c547fb4ebef038a1eaecd0cd6f1c109746f4a9c1fa80329254",
    valide: false
  },
  {
    level: 0,
    phrase: "Si tu travailles bien, tu pourras rester longtemps dans ce milieu",
    mot: "0bb067130523fd8430f6aecfed8b6343bd26b972426886ca695a00553469687b",
    valide: false
  },
  {
    level: 0,
    phrase: "Le bébé moldu est arrivé à poudlard",
    mot: "f15fcb60bd5b1319c35125261e809c852f3f276d3fe5a6d553f6e89caa0ade2e",
    valide: false
  },
  {
    level: 1,
    phrase: "Je veux que les dames s'organisent autrement",
    mot: "cb3a4b17e81be7e645d6791ee21afa92586a0dd02b808f403bd2c0850eeffbea",
    valide: false
  },
  {
    level: 1,
    phrase: "Elle avale ce médicament en entier",
    mot: "52fdd9040a05649bfa9b2defb840c00883c4e085c6882a4c0e45ef334bf6d13a",
    valide: false
  },
  {
    level: 1,
    phrase: "La chaussure remplie d'air a permis à Marty Mcfly de voler",
    mot: "f7c465019a477431771ac66f011f386c3ea0b6528e76fa17f5f3ceab70511985",
    valide: false
  },
  ]

  export { musique }