const voyage = [
  {
    level: 0,
    phrase: "La tique est dangereuse",
    mot: "14069429150abcbf244c4c2fb2c4c46a49c93ca9457263ecd9ebb176de3a58bb",
    valide: false
  },
  {
    level: 0,
    phrase: "Les transes portent du maquillage",
    mot: "97258cda436238fbe4386765074c9e03b1f2d370b07259ab3d45b4fce092850e",
    valide: false
  },
  {
    level: 0,
    phrase: "Tu devineras jamais qui j'ai croisé hier",
    mot: "3dc9b41bd49ad27734e21ca194aaeed0b5c7b0c92616a40eda8d316e4a330979",
    valide: false
  },
  {
    level: 0,
    phrase: "Il passait ses journées à jouer",
    mot: "4bbe60562e048c82f33d228d99a77808f6d9fcea85d5ae6d8fba25da4ba41c25",
    valide: false
  },
  {
    level: 0,
    phrase: "Tu préfères arriver à la bourre ou te perdre en chemin",
    mot: "8a84e406c08ac9594f47222406598f7598e15c55f8044b04813e28c9dee70976",
    valide: false
  },
  {
    level: 0,
    phrase: "Je n'aime pas les ministres impoli",
    mot: "116f54c41d0405dbb10e7b04ebc31e262a5b8c85c1233fcf36eaee344d91ae58",
    valide: false
  },
  ]

  export { voyage }