import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore'

const config = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENGER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID
};

class Firebase {
    constructor () {
        app.initializeApp(config);
        this.auth = app.auth();
        this.db = app.firestore();
    }

    // inscription
    signupUser = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email,password);

    // connexion
    loginUser = (email,password) => 
    this.auth.signInWithEmailAndPassword(email,password);

    // déconnexion
    sinoutUser = () => this.auth.signOut();
    
    user = uid => this.db.doc(`users/${uid}`);

    // Récupérer le mot de passe
    passwordReset = (email) =>
    this.auth.sendPasswordResetEmail(email); 

    passwordUpdate = (newPassword) =>
    this.auth.currentUser.updatePassword(newPassword);

    userDelete = () =>
    this.auth.currentUser.delete();
    
    rankingGet = () => this.db.collection("ranking").doc("F8yQlAxcxSs2fyJqyfcR");

}

export default Firebase;