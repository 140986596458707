let  state = {
  config : {
    id: 0 , 
    score: 0 , 
    reponse: ""
  }
  ,
  phrases : [{
    idPhrase: 0,
    theme : "animaux",
    phrase: 'il va chercher du pain',
    mot: 'vache',
    valide:false
  },
  {
    idPhrase: 1,
    theme : "cinema",
    phrase: 'il tient le fil mieux que tout le monde',
    mot: 'film',
    valide:false
  },
  {
    idPhrase: 2,
    theme : "sport",
    phrase: 'Quand on a une crampe, on peut faire du sport',
    mot: 'crampon',
    valide:false
  },
  {
    idPhrase: 3,
    theme : "love",
    phrase: 'Tu préfères Sam ou Rilès ?',
    mot: 'amour',
    valide:false
  } ,
  {
    idPhrase: 4,
    theme : "voyage",
    phrase: 'La roue tourne beaucoup.',
    mot: 'route',
    valide:false
  },
  {
    idPhrase: 5,
    theme : "manger",
    phrase: 'Sa fourche est tellement rouillée.',
    mot: 'fourchette',
    valide:false
  },
  {
    idPhrase: 6,
    theme : "nature",
    phrase: 'Tu lis peut-être bien mais pas pour moi',
    mot: 'tulipe',
    valide:false
  },
  {
    idPhrase: 7,
    theme : "musique",
    phrase: 'Il dit ce que je pense.',
    mot: 'disque',
    valide:false
  },
  {
    idPhrase: 8,
    theme : "objet",
    phrase: 'Tu me sembles triste quand tes cauchemars t’obsèdent.',
    mot: 'marteau',
    valide:false
  } ,
  {
    idPhrase: 9,
    theme : "vetement",
    phrase: 'Les boxeurs sont forts mais les judokas le sont moins',
    mot: 'calecon',
    valide:false
  }]

};
  

export { state  } 