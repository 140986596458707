import React, {useEffect , useState , useContext } from 'react';
import { FirebaseContext } from '../components/firebase';

import { Link } from 'react-router-dom';
import { cleanup } from '@testing-library/react';

  export default function UpdateScreen (props) {
  
    const firebase = useContext(FirebaseContext);
    const [userSession, setUserSession ] = useState(null);

    const [successDelete , setSuccessDelete ] = useState(false),
            [message , setMessage ] = useState(""),
            [ error , setError ] = useState("");

    const [ actionDelete , setactionDelete ] = useState(false),
            [textDelete , setTexteDelete ] = useState("");

    const deleteAccount = () => {
        //console.log(" ecrit : "+textDelete);

        if(textDelete === "supprimer"){
           // console.log(" enter delete !!!");
            setSuccessDelete(true);

            firebase.user(userSession.uid).delete()
            .then(() => {
                firebase.userDelete()
                .then(() => {
                    setSuccessDelete(true);
    
                }).catch(function(error) {
                    setError("erreur : "+error.message)
                });

            }).catch(function(error) {
                setError("erreur lors de la suppression des données : "+error.message)
            });

        }
        else{
            alert('Code incorrect ! \nVeuillez écrire "supprimer" en minuscule et supprimer les espaces')
        }

    }

    const [state, setState] = useState({
        password: "",
        formErrors: {
          password: ""
        }
    });

    const formValid = ({ formErrors, ...rest }) => {
        let valid = true;

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
    
        // validate the form was filled out
        Object.values(rest).forEach(val => {
            //console.log(" rest a : "+val)
            !val.length > 0 && (valid = false);
        });
    
        return valid;
    };


      const handleSubmit = e => {
        e.preventDefault();

        if (formValid(state)) {
           /* console.log(`
            Password: ${state.password}
            `);
*/
            firebase.passwordUpdate(state.password).then(function() {
                setMessage("Votre compte a été modifier avec succès ! ");
                setTimeout(() => {
                    setMessage("")
                }, 5000)

            }).catch(function(error) {
                setError("Erreur : "+error);
             });

        }
      };


    const handleChange = e => {
      e.preventDefault();
      const { name, value } = e.target;
      let newState = { ...state };
  
      switch (name) {
        case "password":
          newState.formErrors.password =
            value.length < 6 ? "6 charactères minimum sont requis" : "";
            newState.password = value;
          break;
        default:
          break;
      }
      
      setState(newState);
    };
      
    useEffect(() => {
        let listener =  firebase.auth.onAuthStateChanged(user => {
            user ? setUserSession(user) : setUserSession(null);
        })

        return () => {
            listener();
            cleanup();
        };
      }, [firebase]);

      return ( 
          <div style={{marginTop: "5vh"}} className="col-lg-8 col-12 ml-auto mr-auto text-white">
        <div className="m-auto">

        {successDelete ? 
            <div className="col-12 ml-auto mr-auto text-center">

                <h2 className="">
                    Votre compte a été supprimé avec succès ! <br/>
                    A la prochaine fois <span role="img" aria-label="face"> 😉 </span>
                </h2>
                   <Link to='/' className=" btn btn-light mt-4">
                    Menu Principal
                    </Link>
            </div>
                :
                <div className="modal-body">
                    <Link to='/' className=" btn btn-dark">
                    ← Retour
                    </Link>

                    <form className="container-fluid form-connect mt-4" onSubmit={handleSubmit} noValidate>
                        <div className="row">

                        <div className="col-12 text-center ">
                            <h1 className=" mb-4 " >Modification du compte</h1>
                        </div>
                        
                        {message && 
                        <p className="col-12 text-center text-success">
                            { message }
                        </p>}

                        <div id="password" className="col-12 text-center mb-4">
                            <input
                            className={ ' form-control ' + ( state.formErrors.password.length > 0 ? "error " : null)}
                            placeholder="nouveau mot de passe..."
                            type="password"
                            name="password"
                            minLength={6}
                            maxLength={120}
                            noValidate
                            value={state.password}
                            onChange={handleChange}
                            />
                        </div>
                        <div className="col-12 text-center text-danger font-weight-bold">
                            {state.formErrors.password.length > 0 && (
                            <span className="errorMessage">{state.formErrors.password}</span>
                            )}
                        </div>
                        
                        <div className="col-12 text-center " >
                        {error !== "" && 
                            <span className="text-danger font-weight-bold"> 
                                {error} 
                            </span> }                           
                        </div>
                        
                            
                    <div className="col-6">
                        <button className={" btn " + (actionDelete ? " btn-dark " : " btn-danger ")} type="submit"
                        onClick={() => setactionDelete(!actionDelete)}>{ actionDelete ? "Annuler" : "supprimer compte"}
                        </button>

                    </div>

                    <div className="col-6 text-right">
                        <button className="btn btn-primary " type="submit"
                        disabled={formValid(state) ? false : true}
                        onClick={handleSubmit}>Modifier
                        </button>                                
                    </div>

                {actionDelete &&
                <>
                    <div className="col-6 mt-4">
                        <input className=" form-control" maxLength={10} onChange={e => setTexteDelete(e.target.value)} placeholder="supprimer"/>
                        <label>écrivez (supprimer) puis validez</label>     
                    </div>

                    <div className="col-6 mt-4">
                        <button id="codeDelete" className=" btn btn-danger " type="submit"
                        onClick={deleteAccount}>supprimer mon compte
                        </button>                 
                    </div>
                    </>
                }
                    </div>
                </form>

                </div>
            }
        </div>
    </div>
    )
}
