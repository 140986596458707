const animaux = [
  {
    level: 0,
    phrase: "Tu préfères les petites boules ou les grosses boules ?",
    mot: "16f5551f701388c607a9a934dd4d9bf17e0390b84de0188b3952b2746c0d9a2f",
    valide: false
  },
  {
    level: 0,
    phrase: "Il chie un paquet de fois",
    mot: "f0a34945f0daa984f96b013de505a0c14d8375b01951a70d4fff2df79357c212",
    valide: false
  },
  {
    level: 0,
    phrase: "Dans l'étang, il y a des oies horribles",
    mot: "1fb96943919873472147b155300255723d0cda219501a81bca4ca0b97f66ab4c",
    valide: false
  },
  {
    level: 0,
    phrase: "Je veux que Léo parte à l'école",
    mot: "359f7793cbd65f175164737b137ecef5b1a3d2d2e9a57d34559f88cadacb9ea1",
    valide: false
  },
  {
    level: 0,
    phrase: "La biche va laisser des traces",
    mot: "1d72b6d9326cf6738851ccc592ff93df00bb605609b7f6e525d7eeab513af494",
    valide: false
  },
  {
    level: 0,
    phrase: "J'aime bien les chansons de Wakfou remixé",
    mot: "968f184d4c36d14568accaa1c90a1695c4de6a4103b21d56d95eac72c4e3754c",
    valide: false
  },
  {
    level: 0,
    phrase: "Je ne peux pas chanter chez moi, car il y a trop d'écho à la maison",
    mot: "c88cf9468f1701ff059abe2c172a0da5edd6af83ae80ca1a7579e291c38df6d9",
    valide: false
  },
  {
    level: 1,
    phrase: "Peter pan goal un samedi soir pendant le crépuscule",
    mot: "f1aad40ae581652d64987994dd572c44db4e326b3132d5e313fa04df97488a5c",
    valide: false
  },
  {
    level: 1,
    phrase: "Tu peux abattre qu'un seul ennemi",
    mot: "1516dcc4c545a921f86bee69579f409c94b0a307c606d90209581a76a8d84939",
    valide: false
  },
  {
    level: 1,
    phrase: "Quand je balayais, Nestor est venu me chercher",
    mot: "e2159d950ea73ac8ab4286a1014603c2754edeeef0c10ae77d96f8200daa2fcd",
    valide: false
  },
  ]

  export { animaux }