const nature = [
  {
    level: 0,
    phrase: "Je trouve que ce plant te correspond mieux",
    mot: "4a7745aaf8c1a0bd18056159cd94f2c85b2fc544a356c9cae4a5518faff2b9bc",
    valide: false
  },
  {
    level: 0,
    phrase: "L’IA ne comprend pas",
    mot: "e41436ff88389322d18bd280ea100903c03dc27a33ac5096d8f66d45c148063d",
    valide: false
  },
  {
    level: 0,
    phrase: "La gifle heurte sa joue",
    mot: "773d0439dc2a8982b55e1cd3afe989b4852b140dc98b0bde23faf5d043df4a3b",
    valide: false
  },
  {
    level: 0,
    phrase: "Les engrais ne périment que les samedis",
    mot: "9712315c8f2f00925b0aa029a4fc764c82a19fa8004721121928868c19fbfeca",
    valide: false
  },
  {
    level: 0,
    phrase: "Il n'y a pas de race innée",
    mot: "e8aa4ebdb0d251396e185aeaaecee7d6ee35848fb7cd8ca3cb162b6b01679b2c",
    valide: false
  },
  {
    level: 0,
    phrase: "Quand tu arrives au guichet, ne tremble surtout pas",
    mot: "c6caa1d33c5301e2a4328e6623e615ffaf2a43b92d5f94f42ad748643889f293",
    valide: false
  },
  {
    level: 0,
    phrase: "Les petits jeunes aussi",
    mot: "c8e591ae3ea7f3fc6f92fd993ebece1ad3210c5d2d4a4579618a77a6ff6c8488",
    valide: false
  },
  {
    level: 0,
    phrase: "Je ne veux pas le lit-là",
    mot: "e11ffc90b9c0c1769debd2e2d57d9d9cafcf30b904d194c6572bab990d59cf37",
    valide: false
  },
  {
    level: 1,
    phrase: "Son flow rallonge la durée d'écoute du morceau",
    mot: "12fad53d7ead16e0de5e2f4ecf70102a8f1971916c5a1bd7dbf7360f5d5ac1b0",
    valide: false
  },
  {
    level: 1,
    phrase: "Les poux se manifestent chez ceux qui n'aiment pas la nature",
    mot: "08a65f7ae2926eddbee638d21db1f722895d70bf5ab5a977d57be9536df6ed68",
    valide: false
  },
  {
    level: 2,
    phrase: "L'Etat minifie les risque",
    mot: "f3c65d51f3e1a77cbbb5f0b961bd8fb4144acbbd7a5bf025686939d76dc85e3b",
    valide: false
  },
  ]

export { nature }