const manger = [
  {
    level: 0,
    phrase: "Le poisson frit te correspond mieux",
    mot: "10ae5d12b277b7eb15fdc36f8a6a441df2446215e0d3bbfffd2e97e82736f2c3",
    valide: false
  },
  {
    level: 0,
    phrase: "Jean Lassalle a démenti les propos de son adversaire",
    mot: "363ab4c828cfca6a0cfea4acc005a24c5ee9bb4e7f6804c4c15cc287fa640993",
    valide: false
  },
  {
    level: 0,
    phrase: "Ils savent où rester",
    mot: "15cba060e5e86fe1d0dc5800229906a74a334987842a9efbce04fdcc5b7b5ed3",
    valide: false
  },
  {
    level: 0,
    phrase: "Il dit gérer la maison, mais il ne fait rien de cela",
    mot: "d58a1dda048e497941a7331839dc0308d281cc5f9c51b4feeea7337ed05265c5",
    valide: false
  },
  {
    level: 0,
    phrase: "Finalement, la bête ravitaille toute la famille",
    mot: "18824df0efe539368db872fbb60b322ec115320c9fe802f1d18ed126f1b9412e",
    valide: false
  },
  {
    level: 0,
    phrase: "J'ai vu le pape un lundi matin",
    mot: "5c78c8e7160e76ba9b6c41f74247bb7ba4887f5c06faecd6b9a009785b248c72",
    valide: false
  },
  {
    level: 0,
    phrase: "Les pas te mèneront au tueur",
    mot: "42f526c1a6716f793dad1fbbd6e61e824f09976f980b88e095407887f0455ee9",
    valide: false
  },
  {
    level: 0,
    phrase: "Ne bagarre pas, contre, n'importe qui",
    mot: "44099d875586a9f3b2c484cbe2203e6dd5cbf8025b32c866e4b3bc1e423f6a87",
    valide: false
  },
  {
    level: 1,
    phrase: "Le faire assied est tellement plus agréable",
    mot: "c4c2cb954c80b3e8e2965967673e5821432dc61002e246ff4acc84f07654eaa5",
    valide: false
  },
  {
    level: 1,
    phrase: "Je n'aime pas quand les rats bestiaux se manifestent",
    mot: "348b27b2cb57a61dd979d7c79f60175861c26f397a500227e6a34b281ef7f2ba",
    valide: false
  },
  {
    level: 1,
    phrase: "Le pou est le petit insecte grisâtre que l'on retrouve dans les cheveux infectés",
    mot: "b78bf1ae622f83b3599edff8fab07405170d74888ee784f046e070099ffdf5ed",
    valide: false
  },
  {
    level: 1,
    phrase: "La bague rapproche le couple",
    mot: "1a51fc091b8e29f527a3cae718b517d29177262297a8aaa777c63cf310758191",
    valide: false
  },
  ]

  export { manger }