import React, {useEffect , useState , useContext } from 'react';
import { FirebaseContext } from '../components/firebase';

import $ from 'jquery';
import { Link } from 'react-router-dom';
import { cleanup } from '@testing-library/react';

const pseudoRegex = RegExp("^[A-z0-9._-]{0,10}$");

const emailRegex = RegExp(
    /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  );


  export default function SignUpScreen (props) {
  
    const firebase = useContext(FirebaseContext);
    //console.log(firebase);

    const form = {
        pseudo : "",
        email: "",
        password: "",
        formErrors: {
          pseudo : "",
          email: "",
          password: ""
        }
    }

    const [state, setState] = useState(form);
    const [forgotPassword , setforgotPassword ] = useState(false);
    const [signIn , setSignIn ] = useState(true); 
    const [error, setError ] = useState('');
    const [message , setMessage ] = useState("");
    var timer;
    const formValid = ({ formErrors, ...rest }) => {
        let valid = true;
        if(signIn || forgotPassword){
            rest.pseudo = "true";
            if(forgotPassword)
                rest.password = "true"
        }

        // validate form errors being empty
        Object.values(formErrors).forEach(val => {
            val.length > 0 && (valid = false);
        });
    
        // validate the form was filled out
        Object.values(rest).forEach(val => {
            //console.log(" rest a : "+val)
            !val.length > 0 && (valid = false);
        });
    
        return valid;
    };


      const handleSubmit = e => {
        e.preventDefault();

        if (formValid(state)) {
            /*console.log(`
            --SUBMITTING--
            Pseudo: ${state.pseudo}
            Email: ${state.email}
            Password: ${state.password}
            `);
*/
            if(forgotPassword){

                firebase.passwordReset(state.email)
                .then(() => {
                    setMessage(`Consultez votre email ${state.email} pour changer votre mot de passe`)
                    setState({...form});
                    setError("");

                    timer =  setTimeout(() => {
                        props.history.push('/');
                    }, 5000)
                })
                .catch(error => {
                    setState({...form});
                    setError(error.message);
                })
            }
            else if(signIn){
                // envoi a fire base
                firebase.loginUser(state.email,state.password)
                .then(user => {
                    setState({...form});
                    setError("");
                    props.history.push('/');
                })
                .catch(error => {
                    //setState({...form});
                    setError(error.message);
                })
            }
            else{
               // console.log("inscription ");
                // envoi a fire base
                firebase.signupUser(state.email,state.password)
                .then(( authUser => {
                    return firebase.user(authUser.user.uid).set({
                        pseudo: state.pseudo,
                        email : state.email,
                        updateAt : new Date()
                    })
                    
                }))
                .then(() => {
                    setState({...form});
                    setError("");
                    props.history.push('/');

                })
                .catch(error => {
                    setState({...form});
                    setError(error.message);
                })
            }

        } else {
            //setError("Formulaire invalide ! ");
        }
      };

    const changePage = () => {
        if(forgotPassword){
            $("#password").fadeIn();
            setforgotPassword(false);
        }

        if(signIn){
            $("#pseudo").fadeIn();
        }
        else{
            $("#pseudo").fadeOut();
        }

        setSignIn(!signIn);

    }

    const handleForgotPassword = (val) => {
        if(val){
            $("#password").fadeOut();
        }
        setforgotPassword(true);
    }

    const handleChange = e => {
      e.preventDefault();
      const { name, value } = e.target;
      let newState = { ...state };
  
      switch (name) {
        case "pseudo":
            newState.formErrors.pseudo = pseudoRegex.test(value) 
            ? "" : "pseudo invalid";
              newState.pseudo = value;
            break;
        case "email":
          newState.formErrors.email = emailRegex.test(value)
            ? ""
            : "email invalid";
            newState.email = value;
          break;
        case "password":
          newState.formErrors.password =
            value.length < 6 ? "6 charactères minimum sont requis" : "";
            newState.password = value;
          break;
        default:
          break;
      }
      
      setState(newState);
    };
      
    useEffect(() => {
        
        return () => {
          clearTimeout(timer);
        };
      }, [timer]);

      return ( 
          <div style={{marginTop: "5vh"}} className="col-lg-8 col-12 ml-auto mr-auto text-white">
        <div className="m-auto">
                    <div className="modal-body">
                        <Link to='/' className=" btn btn-dark">
                        ← Retour
                        </Link>

                        <form className="container-fluid form-connect" onSubmit={handleSubmit} noValidate>
                            <div className="row">

                            <div className="col-12 text-center ">
                                <h3>Un compte Coolespace ?</h3>
                                
                                <h1 className=" mb-4 " >{ !forgotPassword ? 
                                signIn ? "Connexion" : "Inscription"
                            : " Mot de passe oublié " }</h1>
                            </div>

                            {forgotPassword &&
                            
                            <div className="col-12 text-center ">
                                <p>Envoi d'un mail pour la récupération de mot de votre passe</p>
                            </div>}
                            {message && <div className="col-12 text-center ">
                                <p className="text-success"> { message }</p>
                            </div>}
                            <div id="pseudo" className="col-12 text-center mb-3 " style={{display : "none"}}>
                                <input
                                className={ ' form-control ' + ( state.formErrors.pseudo.length > 0 ? "error " : null)}
                                placeholder="pseudo..."
                                type="text"
                                name="pseudo"
                                maxLength={10}
                                noValidate
                                value={state.pseudo}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 text-center ">
                                {state.formErrors.pseudo.length > 0 && (
                                <span className="errorMessage text-danger font-weight-bold">{state.formErrors.pseudo}</span>
                                )}
                            </div>

                            <div className="col-12 text-center mb-3">
                                <input
                                    className={' form-control ' + ( state.formErrors.email.length > 0 ? "error" : null )}
                                    placeholder="Adresse mail"
                                    type="email"
                                    name="email"
                                    noValidate
                                    value={state.email}
                                    onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 text-center ">
                                {state.formErrors.email.length > 0 && (
                                    <span className="errorMessage text-danger font-weight-bold">{state.formErrors.email}</span>
                                )}
                            </div>

                            <div id="password" className="col-12 text-center mb-4">
                                <input
                                className={ ' form-control ' + ( state.formErrors.password.length > 0 ? "error " : null)}
                                placeholder="mot de passe"
                                type="password"
                                name="password"
                                minLength={6}
                                maxLength={120}
                                noValidate
                                value={state.password}
                                onChange={handleChange}
                                />
                            </div>
                            <div className="col-12 text-center text-danger font-weight-bold">
                                {state.formErrors.password.length > 0 && (
                                <span className="errorMessage">{state.formErrors.password}</span>
                                )}
                            </div>
                            
                            <div className="col-12 text-center " >
                            {error !== "" && 
                                <span className="text-danger font-weight-bold"> 
                                    {error} 
                                </span> }
                            {signIn && 
                                <p className="" style={{cursor : "pointer"}}
                                    onClick={() => handleForgotPassword(true)}>mot de passe oublié. changer 
                                </p> }                                
                            </div>
                            
                                
                            </div>
                        </form>
                    </div>

                    <div className="rowmodal-footer pb-3 text-center">
                        <div className="row">
                            <div className="col-6 text-center">
                                <button className="btn btn-light " type="button"
                                onClick={() => changePage()}>
                                    { "" + (signIn ? " inscription" : " Connexion") }
                                </button>
                                <p className="ml-2">{signIn ? "Pas inscrit" : "Déjà inscrit" }</p>
                            </div>
                            
                            <div className="col-6 text-center">
                                <button className="btn btn-primary " type="submit"
                                disabled={formValid(state) ? false : true}
                                onClick={handleSubmit}>{ !forgotPassword ?  signIn ? "Connexion" : "Inscription" : "Envoyer" } </button>                                
                            </div>

                        </div>

                    </div>
                    </div>
                </div>
    )
}
