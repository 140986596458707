import React, {useState} from 'react';

import {InfoCircle } from 'react-bootstrap-icons';

import { Modal } from 'react-bootstrap'

import { Link } from 'react-router-dom';

function ContentExplication(props) {
  
  const [showWord , setShowWord ] = useState(false); 
  const [ showExemple , setShowExemple] = useState(false);
  const [ showMoreRule , setShowMoreRule] = useState(false);

  
  return ( <Modal
    {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter">
        Règles du jeu
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="text-center">
      <h6 className=" ">1 : Le mot s'entend dans la phrase</h6>
      <h6 className=" ">2 : Le mot a un lien avec le thème</h6>
      <h6 className=" ">3 : Le mot ne peut pas être contenu dans un autre mot</h6>


      <div className="col-12 ">
      {
        showExemple ?
            <>
            <h6 className=" text-success" bgcolor="green">
              Exemple <br/> Avec le thème nourriture et la phrase : 
            </h6>
            <p className="font-weight-bold">
              Les <span className={showWord ? "text-danger " : ""}>poux lai</span>ssent des traces.
            </p>
            <p >Le mot caché est <span className={" border border-dark rounded mb-4 p-1 font-weight-bold " + (showWord ? "text-dark " : "text-white")} 
            onClick={() => setShowWord(true)}> Poulet </span></p>
          </>
          :
          <button className="btn btn-success mb-3"
          onClick={() => setShowExemple(true)}>
            exemple
          </button>
          }
      </div>

      <div className="col-12 ">
          {
            showMoreRule ?
            <>
            <h6 className=" text-danger ">
            <span role="img" aria-labelledby="warning"> ⚠️
            Des leurres peuvent être présents. 😉</span></h6>
            <p className="text-danger">
            Seuls les chiffres et les lettres sont autorisés <br/> contrairement aux caractères spéciaux et aux accents (é) qui ne sont pas autorisés.</p>
        </> 
        :
        <button className="btn btn-danger mb-3"
        onClick={() => setShowMoreRule(true)}>
          + règles
        </button>
        }

        
          <div className="container-fluid">
          <div className="row">

            <div className={showExemple ? "col-6 " : " d-flex justify-content-center col-12"}>
                <div className="mt-4 text-secondary">
                  <span role="img" > 🕊️ 1 </span> Enfantin <br/>
                </div>

                <div className="mt-4 text-warning">
                  <span role="img" > 🐯 2 </span> Tigre <br/>
                </div>

                <div className="mt-4 text-success">
                  <span role="img" > 🐲 3 </span> Dragon <br/>
                </div>

                <div className="mt-4 text-danger">
                  <span role="img" > 👾 4 </span> Demon <br/>
                </div>
              </div>
        {
          showExemple &&

            <div className="col-6">
              <p>Autre jeux de réflexion Brainspace :</p>
                <a href="https://play.google.com/store/apps/details?id=com.coolespace.brainspace" ><img className="col-8 border border-secondary rounded bg-dark "
                    src="/img/brainspace.png"
                    alt="brainspace" /></a>
                </div>
    
          }
          </div>

        </div>

          </div>

    </Modal.Body>
    <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => props.onHide()} >retour</button>
        <Link to="/training" className="col-5 btn btn-primary offset-4" // 
        href="/training"
        >Entrainement </Link> ⟶
    </Modal.Footer>
  </Modal>
  )
}

export default function ModalInfo(props) {
  
  return (
    <>
      <InfoCircle id="info-icon" className="info-icon shadow-lg" 
           onClick={() => props.setShowModalTraining(true)} 
            //size={sizeInfo} 
            color="orange"
            style={{cursor: "pointer"}}/>
      <ContentExplication
        show={props.show}
        onHide={() => props.setShowModalTraining(false)}
      />
      
    </>
  );
}
