const relation = [
  {
    level: 0,
    phrase: "Il hait me pardonner",
    mot: "325a09705c27432bf85b76a10299a939261363598cf52e1144f7b91e8e2f4448",
    valide: false
  },
  {
    level: 0,
    phrase: "J'espérais que l'eau vienne à moi",
    mot: "686f746a95b6f836d7d70567c302c3f9ebb5ee0def3d1220ee9d4e9f34f5e131",
    valide: false
  },
  {
    level: 0,
    phrase: "Je veux son âme ou rien d'autre",
    mot: "30fb30a1e4ccbdc780770d44c175eb28bde824daf6d1d46185cdb9ca122b40c5",
    valide: false
  },
  {
    level: 0,
    phrase: "Il cale un objet sous ma table",
    mot: "d6d48143c3c816bc05119beaf530bb8794603f69df6151c4377ecd9f2fb85376",
    valide: false
  },
  {
    level: 0,
    phrase: "On peut se faire mal en brassant de la bière",
    mot: "ddf9056333edfce08d5a1ce890d6c7664302c4a1ab692138b9eaf53a3c601f21",
    valide: false
  },
  {
    level: 0,
    phrase: "Le mari, âgé de 22 ans se trompe de femme",
    mot: "a6f0d8f81a437475d7561c640707e792e3f77be8bd46e4501c81afac1c4b0f9d",
    valide: false
  },
  {
    level: 0,
    phrase: "Le bijou y restera longtemps",
    mot: "d48dc98ef58d941a57bd102233ca755db9c84523a917205ef9c03019b7d57433",
    valide: false
  },
  {
    level: 0,
    phrase: "Ces raisins secs se démarquent des autres",
    mot: "3ca67c82e5110231b59944a4deade7f8322c55bac240099388cf5c227e4f09c3",
    valide: false
  },
  {
    level: 0,
    phrase: "Tu fais la bise ou tu sers la main",
    mot: "dfb2b4b12b7d9d541e0b99dea14d563ca64a560547f2516ec6c88d2efc739a9e",
    valide: false
  },
  {
    level: 0,
    phrase: "Sa trahison me coupe le souffle",
    mot: "6cf382fca2a10c63b223fd6c338f55e67b5616a94ae4bf115b1bf13220b1a23a",
    valide: false
  },
  {
    level: 0,
    phrase: "Fait l'a si on t'y oblige",
    mot: "12a0fd5cb657f5b93ff38fbfe47b6e17ea51b5fc3b3d99c59b31d28d32c7d0fa",
    valide: false
  },
  {
    level: 1,
    phrase: "Même guillotiné, le cou reste fragile",
    mot: "4917b6fbab6b658741876cdc442ed37662e6d4386aaea5a54beca5cfef624afc",
    valide: false
  },
  {
    level: 1,
    phrase: "Il bagarre en contrant ses adversaires",
    mot: "d62c5ab3d190aee0a75ac4ea0dba1da193a49f79f83c15219e4b84e8f253b1cb",
    valide: false
  },
  ]

  export { relation }