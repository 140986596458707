const vetement = [
  {
    level: 0,
    phrase: "Le jus peut se conserver",
    mot: "b613d13884afaaca8f1ad4b8274ac6a0ee22d97b74503697064078cef753bbfd",
    valide: false
  },
  {
    level: 0,
    phrase: "Quand il fait chaud, c'est tellement bien",
    mot: "ded303782efe02716030024ee1d957184b60124f0d02c0c9d95f2f3ef18285d2",
    valide: false
  },
  {
    level: 0,
    phrase: "Si tu as des coups de soleil, c'est qu'il fait chaud sur Toulouse",
    mot: "122781b434eccab0607f9da14dac0189ccdb8392dca18fb6ff8f5347c20cb4ae",
    valide: false
  },
  {
    level: 0,
    phrase: "Le casque est tellement cher",
    mot: "1b46d57eaab2c67a5678b1d07f868b4b23a40dfff51d548c3142fc2b13b4a0bf",
    valide: false
  },
  {
    level: 0,
    phrase: "Les citoyens doivent payer les impôts locaux",
    mot: "1317dfa6a0c51245a1fbd37c6de9819ac469d2e5f71f70a42eec6c6181a30fa7",
    valide: false
  },
  {
    level: 0,
    phrase: "Je n'ai jamais vu de la mente au miel",
    mot: "4201d6dadae104cb8411934a4f32979dfd4940a7b20ef2b4a2f4a503f36da905",
    valide: false
  },
  {
    level: 0,
    phrase: "Si tu frappes hulk tu iras à l’hôpital",
    mot: "c5f5571c2c9c437485d5c572a3ad9efe51db8839cae2414732f051eaca6293fb",
    valide: false
  },
  ]

  export { vetement }