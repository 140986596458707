import { animaux } from './animeaux-data';
import { cinema } from './cinema-data';
import { manger } from './manger-data';
import { musique } from './musique-data';
import { nature } from './nature-data';
import { objet } from './objet-data';
import { relation } from './relation-data';
import { sport } from './sport-data';
import { vetement } from './vetement-data';
import { voyage } from './voyage-data'

//const listTheme = ["animaux","cinema","manga","manger","musique","nature","objet","relation","sport","vetement","voyage"];
const listNameTheme = []; // <=> const listTheme = ["animaux","cinema","man...

const listPhrasesTheme = { 
    animaux ,
    cinema,
    manger,
    musique,
    nature,
    objet,
    relation,
    sport,
    vetement,
    voyage
};

for (const theme in listPhrasesTheme) {
    //console.log(`${theme}: ${listPhrasesTheme[theme]}`);
    listNameTheme.push(theme);
}

export { listNameTheme , listPhrasesTheme }
