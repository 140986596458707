const sport = [
  {
    level: 0,
    phrase: "Le kebab a longtemps été critiqué",
    mot: "371be5769768e9c483d92aa2b77d95503b3995901731f5f2bf75d8ae03e2a477",
    valide: false
  },
  {
    level: 0,
    phrase: "Les fous te balancent tout au visage",
    mot: "6382deaf1f5dc6e792b76db4a4a7bf2ba468884e000b25e7928e621e27fb23cb",
    valide: false
  },
  {
    level: 0,
    phrase: "Les vautours noient le poisson",
    mot: "62c26c027c4e7408681c2cb7f2a93bab32e166f44ea35b5af6b3f7de4e55fbb4",
    valide: false
  },
  {
    level: 0,
    phrase: "Les méchants pions doivent disparaître",
    mot: "d14a7310bdf55b8ffeb7b8462d1f1e3bc6c79a7d80a90e70eae4ef36f223847f",
    valide: false
  },
  {
    level: 0,
    phrase: "Quand on joue, eux restent assied",
    mot: "69781af08345a5073959189230512ce7aab7d5e163ed23cf233c0c12bd7f24be",
    valide: false
  },
  {
    level: 0,
    phrase: "Il n'y a que Maténé qui peut me vaincre",
    mot: "43cb73b3ca6ff9786261863c0ce912a0e59881688be0a3695091b2b26c3472d5",
    valide: false
  },
  {
    level: 0,
    phrase: "Mon esquisse porte tous les projets",
    mot: "ec450786e26a5154173545c402bcc03b20e2e0477d6086fa632a55c85bdcdd83",
    valide: false
  },
  {
    level: 0,
    phrase: "Son dernier écran peut s'incurver",
    mot: "afc76f25d3c3c3c8300a5fe5712dc395f7b44c0c8d8e6a82d09fe406b5cd47db",
    valide: false
  },
  {
    level: 0,
    phrase: "Il mate chaque individu",
    mot: "4945a70fa7f9c13fe1931a3372ac5798140d42eba74d0dd805a4a216ed3a8142",
    valide: false
  },
  {
    level: 1,
    phrase: "Les pays limitrophes et frontaliers de la France sont nombreux",
    mot: "b799253ebae90bc1dc65a45be1763a6f0d3f13190fc0cf5f795e8e4e956ee684",
    valide: false
  },
  {
    level: 1,
    phrase: "Les verbes auxiliaires sont présents dans beaucoup de livres",
    mot: "c39ab4805a1f8550b1339310482a38a36b6fce511d94a72d36d93cc87e0a952f",
    valide: false
  },
  ]

  export { sport }