import React, {useState} from 'react';

import {InfoCircle } from 'react-bootstrap-icons';

import { Modal , Button } from 'react-bootstrap'

import { Link } from 'react-router-dom';


export default function ModalWarning(props) {
  
  return (
    <Modal
        show={props.show}
        onHide={() => props.setShowModalWarning(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
    >
        <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            <h4 className="modal-title text-dark" id="exampleModalLongTitle">Attention <span role="img" aria-labelledby="warning"> ⚠️</span> </h4>

            </Modal.Title>
        </Modal.Header>
        
        <Modal.Body className="text-center">
            <h5>Vous n'êtes pas connecté </h5>
            <p>Votre session ne sera pas sauvegrdé !</p>
            <p>Nous vous conseillons de vous connecter ou de vous inscrire afin de sauvegarder votre progression et
            de pourquoi pas apparaître dans le classement <span role="img" aria-labelledby="face"> 😉</span> </p>

            <div className="col-12 text-right">
                <Link className=" border border-primary rounded p-2" // 
                to="/signUp"
                >Connexion ⟶ </Link>
            </div>
        </Modal.Body>

        <Modal.Footer>
            <div className="row col-12 text-center">
                <div className="col-4">
                <button className="text-dark border border-secondary rounded p-2" onClick={() => props.setShowModalWarning(false)}>Retour</button>
                </div>

                <div className="col-4 mt-2">
                <Link to={"/playScreen/"+props.theme} className="text-danger border border-danger rounded  p-2">
                    Continuer
                </Link>
                </div>
                <div className="col-4 ">
                <div  data-dismiss="modal" className="btn text-success border border-success rounded p-2 cursor-pointer" 
                onClick={() => props.openModalTraining(false)}
                >Entrainement </div>
                </div>
            </div>
        </Modal.Footer>
    </Modal>
  );
}
