import React, {useContext } from 'react';
import { Link } from 'react-router-dom';

import { FirebaseContext } from './firebase';

export default function Menu (props) {
    
    const firebase = useContext(FirebaseContext);

    return ( 
        <div className="btn-group text-right mt-3">
            <button type="button" className="btn btn-light dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                {props.user.pseudo && props.user.pseudo}
            </button>
            <div className="dropdown-menu dropdown-menu-right">
                <Link to="/updateScreen" className="dropdown-item">Modifier</Link>
            <button className="dropdown-item" type="button"
                onClick={() => firebase.sinoutUser()}>Déconnexion</button>
            </div>
        </div>
    )
}