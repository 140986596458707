import React, {useEffect , useState , useContext } from 'react';
import { FirebaseContext } from '../components/firebase';
import { Link } from 'react-router-dom';
import { sha256 } from 'js-sha256';
import { cleanup } from '@testing-library/react';

import { data , mapTheme} from '../state/data-play';

import { listPhrasesTheme } from '../data/_ListTheme'

import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';

import { Modal } from 'react-bootstrap';

import songBonus from './bonus_sound.mp3'

const regex = new RegExp("^[A-z0-9_-]{0,20}$");

const mapLevel = ["1 🕊️","2 🐯","3 🐲","4 👾"];

function PlayScreen (props) {
  //console.log(" params "+JSON.stringify(props.match.params.theme));
  const theme = props.match.params.theme ;
 
  const firebase = useContext(FirebaseContext); 
  const [userSession, setUserSession ] = useState(null);
  
  const phrases = listPhrasesTheme[theme+""] ;
//console.log("phrase : "+JSON.stringify(phrases));
  const [state , setState ] = useState({  config : {
    id: 0 , 
    reponse: ""
  } , phrases });
  const [listResponses , setResponses ] = useState([]);
  const [callList , setCallList ] = useState(false);
  const [show, setShow] = useState(false);
 // const [showCongratulations , setShowCoongratulation] = useState(true)

  const nextInputRef = React.useRef(null);
  const responseInputRef = React.useRef(null);
  const handleChange = e => {
    e.preventDefault();
    const { value } = e.target;
    let newState = { ...state };
    //console.log(JSON.stringify(listResponses)+" > status : "+state.phrases[state.config.id].valide);
    // vérifier la validiter de la valeur regex
    if(value.match(regex)){ 
      
      if(sha256(value.trim().toLowerCase()) === newState.phrases[newState.config.id].mot 
      && newState.phrases[newState.config.id].valide === false){
        nextInputRef.current.focus();
        var audio = new Audio(songBonus);
        audio.play();

        store.addNotification({
          title: 'Info',
          message: '+ 1 😉',
          type: 'success',                         // 'default', 'success', 'info', 'warning'
          container: 'top-right',
          insert: "top",                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          
          dismiss: {
            duration: 2000,
            showIcon: true
          }
        })

        listResponses.push({id : newState.config.id , mot : sha256(value.trim().toLowerCase()).substr(0,1)});

        newState.phrases[newState.config.id].valide = true;
        newState.config.reponse = "" ;
        //console.log("reponse correcte on peu modifier : "+JSON.stringify(listResponses));

        //audio.play(); /* # */

        if(userSession !== null) {
          firebase.user(userSession.uid).update({
            [theme] : listResponses
          })
          .then(() => {
            console.log("Document successfully updated!");
          })
          .catch((error) => {
              // The document probably doesn't exist.
              console.error("Error updating document: ", error);
          });
        }
      }
      else{
        newState.config.reponse = value ;
      }
    }else{
      store.addNotification({
        title: 'Info',
        message: 'Valeur incorrect !',
        type: 'danger',  // 'default', 'success', 'info', 'warning'
        container: 'top-left',
        insert: "top",   // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        
        dismiss: {
          duration: 2000,
          showIcon: true,
          pauseOnHover: true
        }
      })
    }
     setState(newState);

    };
    
    const changePage = e => {
      e.preventDefault();
      const { id } = e.target ;
      setState({ ...state , config : { ...state.config , id : parseInt(id) } })
    }
    
    const backPage = () => {
      
      setState({ ...state , config : { ...state.config , id : state.config.id > 0 ? state.config.id -= 1 : state.config.id } })
      
    };
    
    const nextPage = () => {
      //console.log(" longeur : "+ state.phrases.length)
      setState({ ...state , config : { ...state.config , 
        id : state.config.id < state.phrases.length-1 ? state.config.id += 1 : state.config.id } })
      };

      useEffect (() => {
        let listener =  firebase.auth.onAuthStateChanged(user => {
          user ? setUserSession(user) : setUserSession(null);
        })
        
        if(!listPhrasesTheme[theme+""]){
          props.history.push('/');
        }
        
        if(!state.phrases[state.config.id].valide)
        responseInputRef.current.focus();
        //On récupère les phrases déjà validé
        
        if(userSession !== null && callList === false) {
         // console.log(" idd : "+JSON.stringify(userSession.uid))
 
     // console.log("enter")
      firebase.user(userSession.uid)
      .get()
      .then( doc => {
        if (doc && doc.exists){
          let newState = {...state};
          if(listPhrasesTheme[theme+""]){

            const responses = doc.data()[theme+""];
            if(responses)
              setResponses(responses);

            //console.log(" idd : "+JSON.stringify(userSession.uid))
            newState.phrases = phrases.map(ph => {
              return {...ph,valide : false}
            });
            //console.log(" phrases : "+JSON.stringify(newState.phrases));
            for (const idResponse in responses) {
              newState.phrases[responses[idResponse].id].valide = true;  
            }
          
            setState({...newState});
          }
        }
      })
      .catch( error => {
       // console.log(" error : "+error.message);
      })
      
      setCallList(true);
    }
    else
    //console.log(" idd : "+JSON.stringify(userSession))

    if (state.phrases.filter(p => p.valide === true).length >= state.phrases.length )
        setShow(true);

    return () => {
      listener();
      cleanup();
    }
  }, [props,userSession,firebase,state,callList,listPhrasesTheme,theme])

  return  (<>
      <div className="row pb-2">
        <div className="m-auto">

            <div className="container center-block mt-5 ">
              <div className="row col-md-5 col-12 m-auto justify-content-center text-white">

              <div className="d-flex flex-wrap">
              
              {state.phrases.map((p,ind) => 
                  <div key={ind} id={ind} onClick={changePage}
                  className={  "shadowStyle p-2  m-1  btn " + ( (state.config.id === ind ? 
                    p.valide ? "btn-success border-bottom-2 border-warning" : " btn-warning" 
                    :  p.valide ? "btn-success " : "btn-danger" ) )} >
                      
                    </div> 
                  )
                }
            </div>
            
            <div className="col-12 row font-weight-bold"  style={{color: "wheat"}}>
                  <div className="col-4 " style={{color: "papayawhip"}}>Niv : {state.phrases[state.config.id] && mapLevel[state.phrases[state.config.id].level]}</div>

                  <div className="col-4 text-center" >{state.phrases.filter(p => p.value === true).length}/{state.phrases.length}</div>
                  <div className="col-4 text-right">Score : {state.phrases.filter(p => p.value === true).length}</div>

            </div>

                {/* ----------- Card du thème -----------*/}
                <div className="col-12 text-center text- border p-3 rounded shadowStyle2" 
                style={
                  { backgroundColor : state.phrases[state.config.id] && state.phrases[state.config.id].valide ? "green" : "rgb(235, 134, 63)" }
                }>
                    <h1 >{ theme }</h1>
                    <span role="img" className="display-3" aria-label={theme}>{mapTheme[theme]}</span> 
                </div>

                <div className="col-12 text-center mt-4 ">
                    <p style={{boxShadow: "inset 0 0 1em rgb(202, 140, 129), 0 0 0.5em rgb(160, 51, 51)"}} className="col-12 ml-auto mr-auto p-3 border  rounded font-weight-bold"> 
                    {state.phrases[state.config.id].phrase}
                     </p>
                </div>

                <div className="col-12 mt-4 mb-4 ">
                  <input id="runResponse" name="reponse" 
                    ref={responseInputRef}
                    className={"shadowStyle2 form-control " + ( state.phrases[state.config.id] && state.phrases[state.config.id].valide ? null : " error" )} 
                    placeholder="mot caché..." value={state.config.reponse} onChange={handleChange} />
                </div>    

                <div className="col-4 ">
                  <button onClick={backPage} className="shadowStyle2 btn btn-secondary ">Retour</button>
                </div>

                <div  className="col-4 text-center">
                  <Link to='/' className=" btn btn-danger">Quitter</Link>
                </div>

                <div className="col-4 text-right">
                  <button ref={nextInputRef} type="button" onClick={() => nextPage() } className={"shadowStyle2 btn btn-light "  }>Passer</button>
                </div>

              </div>
            </div> 


        </div>
        
              <Modal
              show={show}
              onHide={() => setShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
              centered
            >
              <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                  <div className="card-header ">
                    <span role="img" aria-label="triangular flag"> 🚩 Fin de partie ! 🚩</span>
                  </div>
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="text-center">
                <div className="card-body">
                  <h5 className="card-title"><span role="img" aria-label="fire conffeti">🔥🎊 Félicitation tu as terminé le theme : {theme} !🎊🔥</span></h5>
                  <p className="card-text text-dark" >Si tu es arrivé jusqu'ici tu devrais peut-être apparaître dans le classement <span role="img" aria-label=" shark">⚔️🦈</span></p>
                  <Link to="/" className="btn btn-primary">
                    Retour au menu principal
                  </Link>
                  <button className="btn btn-dark ml-4"
                    onClick={() => setShow(false)}
                  >
                    fermer
                  </button>
                </div>
                </Modal.Body>
              </Modal>

      </div>
    </>
  );

}

export default PlayScreen;
