const cinema = [
  {
    level: 0,
    phrase: "Il vaut mieux commettre un acte heureux",
    mot: "67df1877c93a6ec10c813acacce26c6b7e3279cc8a85266478e1f294e412f315",
    valide: false
  },
  {
    level: 1,
    phrase: "Les paragraphes y cheminent tout le temps",
    mot: "e5e4e2f14f0107383bbf27b32821a695e00c2cf24d2b54a639a9387005bc7100",
    valide: false
  },
  {
    level: 0,
    phrase: "Dans ce cas, mes rassemblements se dérouleront le samedi soir",
    mot: "89e8b9518d92279489bbdee26f3dc646d921d89a15afbd3a3e0ad695328a3da0",
    valide: false
  },
  {
    level: 0,
    phrase: "J'aime bien quand Sénat rie au Larme",
    mot: "ee26f4137f88e2408f3f2e85b7d753592ca4b8034392d2bbd17247956c849c0a",
    valide: false
  },
  {
    level: 0,
    phrase: "Je mange un plat, tôt le matin",
    mot: "821a80eab5ba9c37b074398325b0d09aec2558a7f8fa1d3afcceb9cb6b0ce24c",
    valide: false
  },
  {
    level: 0,
    phrase: "Je crois que Kassy ne m'a pas rendu son diplôme",
    mot: "b815843425f6ac6c018ef9ee434b4a401202a8b175ded4b53b4ba0fd8069887f",
    valide: false
  },
  {
    level: 0,
    phrase: "Il réalise une figure en apnée",
    mot: "c50338852c83c7f377caf30355207f15306c11d21a8d287026f32f572d85f1ee",
    valide: false
  },
  {
    level: 0,
    phrase: "Il ne monta jamais dans sa chambre",
    mot: "c0d142a8f47ed043a92fd0dc31945d8ffcc6d9ea88e1b3f0538e0c20a15d8217",
    valide: false
  },
  {
    level: 0,
    phrase: "Je regarde les couples en Arménie",
    mot: "64879f7d6b960a01909762d911a32d4582c20010c5641ee90278b644a9e3b525",
    valide: false
  },
  {
    level: 0,
    phrase: "C’est en ce moment que je pleur",
    mot: "8c9771035e10619d9c2e084cc0f914772bfff45bd0b0a37c831f98bdf4a94839",
    valide: false
  },
  {
    level: 1,
    phrase: "Je lui confis le marteau",
    mot: "d0607f7ad2628b2af9158dfba06ce87166e66b15bf68f8f358f9aa27ccb7c321",
    valide: false
  },
  {
    level: 2,
    phrase: "Il ne le saura qu'au recommencement",
    mot: "e60d2eb95ed8ae3bde1f82d35cf4e6b151be22bd651c4da4f1ae6a03a6e07641",
    valide: false
  },
  ]

  export { cinema }